<template>
  <div>
    <div class="sctp-container partTime-view sctp-mar-b10">
      <div class="sctp-pad-tb10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>兼职人才大厅</el-breadcrumb-item>
          <el-breadcrumb-item>兼职详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="notExist === true">
        <div style="padding: 50px 0;" class="sctp-bg-white">
          <empty :message="'兼职不存在或已下架'"></empty>
        </div>
      </template>
      <template v-else>
        <template v-if="partTimeConfig.detail">
          <el-row type="flex" :gutter="10">
            <el-col :span="18">
              <el-row type="flex" :gutter="10">
                <el-col :span="24">
                  <el-row type="flex" :gutter="0">
                    <el-col class="flex flex-col" :span="7">
                      <div class="flex-item1 sctp-bg-white pad15">
                        <el-image
                          :src="partTimeConfig.detail.imgurl"
                          fit="cover"
                          style="width: 100%;height: 240px;"
                        ></el-image>
                        <div class="flex flex-sb">
                          <template
                            v-if="!(user && partTimeConfig.detail && user.userId === partTimeConfig.detail.userid)">
                            <div>
                              <a v-if="partTimeConfig.detail.favorite"
                                 class="sctp-color-main"
                                 @click="doPrivileged(favoriteConfig.onClick)">
                                <i class="el-icon-star-on"></i>
                                已收藏
                              </a>
                              <a v-else
                                 @click="doPrivileged(favoriteConfig.onClick)">
                                <i class="el-icon-star-off"></i>
                                收藏
                              </a>
                            </div>
                            <div>
                              <a @click="reportConfig.showReport">
                                <i class="el-icon-warning"></i>
                                举报
                              </a>
                            </div>
                          </template>
                        </div>
                      </div>
                    </el-col>
                    <el-col class="flex flex-col" :span="17">
                      <div class="flex-item1 sctp-bg-white pad15">
                        <div class="flex flex-sb">
                          <div class="flex sctp-flex-aic">
                            <div>
                              <span class="fz-16">{{ partTimeConfig.detail.president }}</span>
                            </div>
                            <!--<div class="fc-info sctp-mar-l20">-->
                            <!--  <span>{{ partTimeConfig.detail.president }}</span>-->
                            <!--</div>-->
                          </div>
                          <div>
                          <span style="color: #D71D1D;">
                            <span class="fz-16">¥</span>
                            <span class="fz-20 mg-r5">{{
                                partTimeConfig.detail.expectedSalary
                              }}</span>
                          </span>
                            <span class="fz-16">/{{
                                partTimeConfig.detail.salaryUnit.replace('按', '')
                              }}</span>
                          </div>
                        </div>
                        <div class="divider"></div>
                        <div class="pad-t10">
                          <div class="slider-card-title" style="color: #acacac;">兼职编号</div>
                          <div class="pad-t10">
                            #{{ partTimeConfig.detail.jobid }}
                          </div>
                        </div>
                        <div class="pad-t10">
                          <div class="slider-card-title" style="color: #acacac;">擅长</div>
                          <div class="pad-t10">
                            <el-tag class="sctp-mar-r5" type="success"
                                    size="mini"
                                    effect="plain"
                                    v-for="skill in partTimeConfig.detail.jobUserSkillIdList"
                                    :key="skill.id">
                              {{ skill.skillname }}
                            </el-tag>
                          </div>
                        </div>
                        <div class="pad-t10">
                          <div class="slider-card-title" style="color: #acacac;">介绍</div>
                          <div class="pad-t10">
                            <div
                              style="color:#737373;"
                              class="rich-text"
                              v-html="partTimeConfig.detail.aboutMyself"
                            ></div>
                          </div>
                        </div>
                        <template
                          v-if="!(user && partTimeConfig.detail && user.userId === partTimeConfig.detail.userid)">
                          <div class="pad-t10 pad-tb10">
                            <el-button
                              type="primary"
                              size="small"
                              @click="doPrivileged(viewPhoneDialogConfig.onViewClick)"
                            >
                              立即沟通
                            </el-button>
                          </div>
                        </template>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <div class="mg-t15 sctp-bg-white">
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-tabs style="min-height: 400px;" type="border-card">
                      <el-tab-pane label="兼职介绍">
                        <div>
                          <div
                            class="rich-text"
                            v-html="partTimeConfig.detail.introduceDetail"
                          ></div>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="作品集">
                        <div>
                          <template v-for="(works) in partTimeConfig.detail.userworks">
                            <div class="sctp-mar-b15">
                              <div>{{ works.worksname }}</div>
                              <div class="rich-text" v-html="works.details"></div>
                            </div>
                          </template>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="6">
              <el-row :gutter="10">
                <el-col :span="24" class="">
                  <div class="sctp-bg-white">
                    <div class="pad-tb15 pad-lr10">
                      <div class="list">
                        <div style="color: #c4c4c4;" class="title">
                          <div class="dot"></div>
                          <div>兼职类型</div>
                        </div>
                        <div class="value">{{
                            partTimeConfig.detail.jobType
                          }}-{{ partTimeConfig.detail.jobSubType }}
                        </div>
                      </div>
                      <div class="list">
                        <div style="color: #c4c4c4;" class="title">
                          <div class="dot"></div>
                          <div>可工作时间</div>
                        </div>
                        <div class="value">{{ jobTime(partTimeConfig.detail.jobtime) }}</div>
                      </div>
                      <div class="list">
                        <div style="color: #c4c4c4;" class="title">
                          <div class="dot"></div>
                          <div>工作方式</div>
                        </div>
                        <div class="value">{{ jobMethod(partTimeConfig.detail.jobmode) }}</div>
                      </div>
                      <div class="list">
                        <div style="color: #c4c4c4;" class="title">
                          <div class="dot"></div>
                          <div>从业经验</div>
                        </div>
                        <div class="value">{{
                            partTimeConfig.detail.workingYears
                          }}{{
                            partTimeConfig.detail.workingYears
                            && partTimeConfig.detail.workingYears.includes('年') ? '' : '年'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24" class="mg-t15">
                  <div class="sctp-bg-white">
                    <div class="pad-tb15">
                      <div class="slider-card-title mg-b10">工作经历</div>
                      <div class="pad-l20 pad-r10">
                        <template v-for="job in partTimeConfig.job">
                          <div class="list">
                            <div class="title">
                              <div class="dot"></div>
                              <div>
                                {{ job.companyname }} {{ job.president }}
                              </div>
                            </div>
                            <div style="color: #c4c4c4;" class="pad-l15 fz-12">
                              {{ dateToString(job.starttime, 'yyyy-MM-dd') }}~{{
                                dateToString(job.endtime, 'yyyy-MM-dd')
                              }}
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24" class="mg-t15">
                  <div class="sctp-bg-white">
                    <div class="pad-tb15">
                      <div class="slider-card-title mg-b10">教育经历</div>
                      <div class="pad-l20">
                        <template v-for="edu in partTimeConfig.edu">
                          <div class="list">
                            <div class="title">
                              <div class="dot"></div>
                              <div>
                                {{ edu.schoolname }} {{ edu.major }} {{ edu.education }}
                              </div>
                            </div>
                            <div style="color: #c4c4c4;" class="pad-l15 fz-12">
                              {{ dateToString(edu.starttime, 'yyyy-MM-dd') }}~{{
                                dateToString(edu.endtime, 'yyyy-MM-dd')
                              }}
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="24" class="mg-t15">
                  <div class="sctp-bg-white">
                    <div class="pad-tb15">
                      <div class="slider-card-title mg-b10">获奖情况</div>
                      <div class="pad-l20 pad-r10">
                        <template v-for="awarded in partTimeConfig.awarded">
                          <div class="list">
                            <div class="title">
                              <div class="dot"></div>
                              <div class="flex flex-sb">
                                <div class="flex-item1">{{ awarded.awardname }}</div>
                                <div style="width: 80px;" class="mg-l15">{{
                                    dateToString(awarded.awardtime, 'yyyy-MM-dd')
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="pad-l15 fz-12">
                              <template v-for="item in awarded.imgurls">
                                <el-image
                                  style="height: 60px;width: 60px;margin-right: 5px;"
                                  fit="cover"
                                  :src="item"
                                ></el-image>
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-dialog
            center
            title="兼职信息内容举报"
            :visible.sync="reportConfig.show"
          >
            <report
              @success="reportConfig.hiddenReport"
              :target="{
             title: partTimeConfig.detail.president,
             id: id
          }"
              :type="6"></report>
          </el-dialog>
        </template>
      </template>
    </div>
    <el-dialog
      center
      title="查看联系方式"
      width="600px"
      :visible.sync="viewPhoneDialogConfig.showPayDialog"
    >
      <pay :pay-amount="shopInfo && shopInfo.viewContactPrice || 0"
           @pay="viewPhoneDialogConfig.onPay"
           :can-use-reward="true"
      ></pay>
    </el-dialog>
    <el-dialog
      center
      title="联系方式"
      width="500px"
      :visible.sync="viewPhoneDialogConfig.show"
    >
      <div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">手机号码：</div>
          <div>{{ shopInfo && shopInfo.phone || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
          <div>{{ shopInfo && shopInfo.email || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">QQ号：</div>
          <div>{{ shopInfo && shopInfo.qq || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信号：</div>
          <div>{{ shopInfo && shopInfo.wechat || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
          <div>
            <template v-if="shopInfo && shopInfo.wechat_qrcode">
              <el-image
                :src="shopInfo.wechat_qrcode"
                style="width: 80px;height: 80px;"
                fit="contain"
              ></el-image>
            </template>
            <template v-else>
              未设置
            </template>
          </div>
        </div>
        <div class="fz-12 fc-info">
          注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="查看联系方式需支付服务费"
      :visible.sync="openPayAfterFlag"
      width="26%"
      class="register-dialog"
      center>
      <div>
        <div style="text-align: center;color: red;font-size: 20px">{{shopInfo.viewContactPrice.toMoney()}}元</div>
        <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
        <div class="flex flex-center">
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasPhone"></i>
            <i class="el-icon-error" v-else></i>手机号</div>
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasEmail"></i>
            <i class="el-icon-error" v-else></i>邮箱</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasQQ"></i>
            <i class="el-icon-error" v-else></i>QQ</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasWechat"></i>
            <i class="el-icon-error" v-else></i>微信</div>
        </div>
      </div>
      <div class="sctp-flex sctp-flex-center" style="padding-top: 10px">
        <el-checkbox v-model="read">
          我已阅读
          <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《平台服务条款》</a>
          并同意
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">

        <el-button @click="openPayAfterFlag = false" >取消</el-button>
        <el-button type="primary" @click="openPay()">支付</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="平台服务条款"
      :visible.sync="centerDialogVisible"
      class="register-dialog"
      center>
      <div class="rich-text" v-html="productReleaseTerms"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {collection, common, partTime, shop} from "../../../apis";

export default {
  name: "center",
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    empty: () => import('@CMP/common/EmptyData.vue'),
    report: () => import('@CMP/common/UserReport'),
  },
  props: ['id'],
  data() {
    return {
      notExist: null,
      shopInfo: null,
      productReleaseTerms:null,
      openPayAfterFlag:false,
      read:false,
      centerDialogVisible:false,
      partTimeConfig: {
        detail: null,
        edu: [],
        awarded: [],
        job: [],
        getDetail: () => {
          return partTime.detail({
            id: this.id,
            userId: this.user && this.user.userId || null,
          }).then(res => {
            let {retdata, edu = [], awarded = [], job = [], shopInfo} = res;
            // 保存访问记录
            common.viewHistory({
              userId: (this.user && this.user.userId) || null,
              id: this.id,
              type: 6,
              title: retdata.president,
            });
            this.partTimeConfig.detail = retdata;
            this.partTimeConfig.edu = edu;
            this.partTimeConfig.awarded = awarded;
            this.partTimeConfig.job = job;
            this.shopInfo = shopInfo;
          }).catch(res => {
            if (res && res.retCode === '9000') {
              this.notExist = true;
            }
          });
        }
      },
      reportConfig: {
        show: false,
        showReport: () => {
          this.reportConfig.show = true
        },
        hiddenReport: () => {
          this.reportConfig.show = false
        }
      },
      favoriteConfig: {
        onClick: () => {
          if (!this.favoriteConfig.canClick) {
            return;
          }
          this.favoriteConfig.canClick = false;
          collection.addFavorite({
            userId: (this.user && this.user.userId) || null,
            targetId: this.id,
            targetType: 7
          }).then(res => {
            const {retdata} = res;
            this.$set(this.partTimeConfig.detail, 'favorite', retdata);
            if (retdata) {
              this.$message.success('收藏成功')
            } else {
              this.$message.success('取消收藏成功')
            }
          }).finally(() => {
            this.favoriteConfig.canClick = true;
          })
        },
        canClick: true,
      },
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
        onViewClick: () => {
          if (!this.shopInfo) {
            return;
          }
          if (this.shopInfo.showContact === true) {
            this.viewPhoneDialogConfig.show = true;
          } else {
            common.contactClickHistory({
              userId: this.user.userId,
              type: 7,
              id: this.id,
            })
            this.openPayAfterFlag = true
            // this.viewPhoneDialogConfig.confirmPay();
          }

        },
        // confirmPay: () => {
        //   if (!this.shopInfo) {
        //     return;
        //   }
        //   let {shopInfo} = this;
        //   this.$confirm(`<div>
        //       <div class="sctp-red sctp-font-18">${shopInfo.viewContactPrice.toMoney()}元</div>
        //       <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
        //       <div class="flex flex-center">
        //       <div class="sctp-mar-r10">${shopInfo && shopInfo.hasPhone
        //     ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
        //       <div class="sctp-mar-r10">${shopInfo && shopInfo.hasEmail
        //     ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}邮箱</div>
        //       <div class="sctp-mar-r10">${shopInfo && shopInfo.hasQQ
        //     ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}QQ</div>
        //       <div class="sctp-mar-r10">${shopInfo && shopInfo.hasWechat
        //     ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}微信</div>
        //       </div>
        //       </div>`, {
        //     dangerouslyUseHTMLString: true,
        //     confirmButtonText: '支付',
        //     cancelButtonText: '取消',
        //     center: true,
        //     title: '查看联系方式需支付服务费',
        //   }).then(() => {
        //     this.viewPhoneDialogConfig.showPayDialog = true;
        //   })
        // },
        onPay: ({payWay}) => {
          shop.viewContactInformation({
            userId: this.user.userId,
            shopId: this.shopInfo.shopid,
            payWay: payWay,
            eType: 7,
            eId: this.id
          }).then(() => {
            this.viewPhoneDialogConfig.showPayDialog = false;
            this.partTimeConfig.getDetail().then(() => {
              this.viewPhoneDialogConfig.show = true;
            });
          })
        },
      },
    }
  },
  methods: {
    openPay(){
      if (!this.read){
        this.$message.warning('请确认阅读条款');
        return
      }
      this.openPayAfterFlag =false
      this.viewPhoneDialogConfig.showPayDialog =true

    },
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '平台服务条款'
      }).then(res => {
        const {retdata} = res;
        this.productReleaseTerms = retdata
      })
    },
  },
  computed: {
    jobTime() {
      return function (type) {
        return {
          1: "全部",
          2: "工作日",
          3: "周末",
          4: "节假日",
        }[type] || '';
      }
    },
    jobMethod() {
      return function (flag) {
        return {
          1: "远程在家",
          2: "偶尔到岗",
        }[flag] || '';
      }
    }
  },
  beforeMount() {
    this.partTimeConfig.getDetail();
    this.getProductReleaseTerms()
  }
}
</script>

<style scoped lang="scss">
.partTime-view {
  & ::v-deep .el-tag--plain.el-tag--success {
    background-color: #fff;
    border-color: lighten($main-color, 25%);
    color: $main-color;
  }


  .list {
    margin-bottom: 5px;

    .title {
      display: flex;
      line-height: 24px;

      .dot {
        position: relative;
        width: 10px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        margin-right: 5px;

        &:after {
          position: absolute;
          content: '';
          background-color: #c4c4c4;
          display: block;
          height: 5px;
          width: 5px;
        }
      }
    }

    .value {
      padding: 5px 5px 5px 20px;
    }


  }


  .slider-card-title {
    padding-left: 15px;
    border-left: 3px solid $main-color;
  }

  .divider {
    height: 1px;
    border-bottom: #dddddd 1px dashed;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 100%;
  }

  .rich-text {
  }
}
 ::v-deep .el-dialog__body {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
